input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 300px;
  height: 2px;
  background: #ddd;
  border: none;
  border-radius: 3px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 5px;
  width: 15%;
  border-radius: 0%;
  background: #000;
  margin-top: -4px;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #ccc;
}
.carousel-container-with-scrollbar {
  padding-left: 0px !important;
  padding-bottom: 20px;
  overflow: visible !important;
}
.custom-slider {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
}
.custom-slider__input {
  width: 100%;
}
.slider-image-item {
  padding-right: 20px;
}
.react-multi-carousel-track {
  width: 80vw;
  margin: 50px 0px;
}
.react-multi-carousel-dot-list {
  top: 100%;
}
.react-multi-carousel-dot-list button{
  position: relative;
  outline: none;
  border: none;
  color: white;
  margin-right: 25px;
  font-size: 22px;
  background-color: transparent;
}
.react-multi-carousel-dot-list button::before{
  position: absolute;
  width: 26px;
  height: 1px;
  color: #ffffff;
  content: "";
  background: #ffffff;
  left: 22px;
  top: 12px;
}
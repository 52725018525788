/* large desktop */

/* small desktop max*/
@font-face {
  font-family: "Fetish-Heavy";
  src: url(../../fonts/fetish/Fashion-Fetish-Heavy.ttf);
}
.header-logo {
  font-family: "Fetish-Heavy";
}
/* @media (max-width: 1377px) {
  .header-logo {
    width: 75%;
  }
  .my-xxxl-4 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .p3 {
    font-size: 14px;
  }
  .p4 {
    font-size: 28px;
  }
  .p5 {
    font-size: 16px;
  }
  .p6 {
    font-size: 16px;
  }
  .p7 {
    font-size: 22px;
    line-height: 1;
  }
  .p8 {
    font-size: 16px;
  }
  .p9 {
    font-size: 30px;
    line-height: 1.3;
  }
  .p10 {
    font-size: 15px;
  }
  .p15 {
    font-size: 15px;
    line-height: 1.4;
  }
  .p17 {
    font-size: 18px;
  }
  .lh-2 {
    line-height: 1.5;
  }
  .font-16,
  .p11 {
    font-size: 13px;
  }
  .p12 {
    font: normal normal normal 17px Helvetica;
  }
  .p13 {
    font-size: 17px;
  }
  .p18 {
    font: normal normal normal 15px/25px Helvetica !important;
  }
  .p19 {
    font: normal normal bold 24px/31px Open Sans;
  }
  .p21 {
    font: normal normal bold 15px/25px Open Sans;
  }
  .p22 {
    font: normal normal normal 13px/25px Helvetica;
  }
  .p23 {
    font: normal normal bold 18px/30px Open Sans;
  }
  .p25 {
    font: normal normal bold 17px/30px Open Sans;
  }
  .p8-14 {
    font-size: 14px;
  }
  .font-14 {
    font-size: 13px;
  }
  .number {
    font-size: 5rem;
  }
  .col-desktop-w {
    flex: 0 0 31% !important;
    max-width: 31% !important;
  }
  .card-d-para {
    font: normal normal normal 14px/1.5 Helvetica !important;
  }
  .overlay-box {
    height: 50px !important;
  }
  .overlay-box > svg {
    height: 40px !important;
    margin-top: 7px;
  }
  .carousel-indicators {
    bottom: -16% !important;
  }
  .mt-xxl-5 {
    margin-top: 1rem;
  }
  .px-xxl-5 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .getin-btn,
  .profile-btn {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .pxx-3 {
    padding-right: 1.4rem;
    padding-left: 1.4rem;
  }
  .p-xl-resp {
    padding: 2.5rem !important;
  }
  .serv-dim {
    height: auto;
  }
  .w-32 {
    width: 32px;
  }
  .mx-xxl-1 {
    margin-left: 0.07rem;
    margin-right: 0.07rem;
  }
  .my-xxl-3 {
    margin: 0 !important;
  }
  .px-xxl-5 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .pt-xxl-5 {
    padding-top: 2rem;
  }
  .navbar-nav > a {
    padding-top: 23px !important;
  }
  .h-desktop {
    width: 30px;
  }
  .team-img {
    height: auto !important;
  }
  .stky-h-n-link {
    font-size: 23px;
  }
  .service-img {
    width: 100%;
  }
  .company-card-title {
    z-index: 9;
    margin-top: -15%;
  }
  .content__container {
    height: 120px !important;
  }
  .carousel-bg {
    height: auto;
  }
  .content__container__list__item {
    line-height: 1.3;
  }
  .font-xl-16 {
    font-size: 16px;
  }
  .svg-resp {
    height: 70% !important;
  }
  .contact-btn {
    width: 151px;
    height: 39px;
    font: normal normal normal 15px/25px Helvetica;
    border-radius: 64px;
  }
  .py-desktop-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .card-h-resp {
    height: 210px;
  }
  .py--80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .py--60 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py--40 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .mt-lg--16 {
    margin-top: -16%;
  }
  .pb--80 {
    padding-bottom: 60px;
  }
  .large-img-value {
    height: 252px;
  }
  .small-img-value {
    height: 121px;
  }
  .mtt-xl-3 {
    margin-top: 2rem !important;
  }
  .mx-xxl-3 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .py--60 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pxx-10 {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .sub-black-heading {
    font-size: 19px !important;
  }
  .sticky-menu {
    top: 71px !important;
    padding: 20px 0px !important;
  }
} */

/* tab max */

@media (max-width: 1050px) {
  .p-xl-resp {
    padding: 2rem !important;
  }
  /* .sticky-top {
    box-shadow: none !important;
    max-width: 100%;
    margin: auto;
    padding: 10px 0px !important;
  } */
  .link-active::after {
    background: none !important;
  }
  .content__container {
    font-size: 84px !important;
  }
  .r-13 {
    right: -14%;
  }
  .p1-23 {
    font-size: 23px;
  }
  .p2-25 {
    font-size: 25px;
  }
  .p3 {
    font-size: 12px;
  }
  .p3--16 {
    font-size: 15px;
  }
  .p5 {
    font-size: 15px;
  }
  .pt5 {
    padding-top: 4.5rem !important;
  }
  .mb--5 {
    margin-bottom: -14%;
  }
  .mt--5 {
    margin-top: -5%;
  }
  .pt4 {
    padding-top: 4rem !important;
    padding-bottom: 3rem !important;
  }
  .card-h-resp {
    height: auto;
  }
  .pylg-5 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .p3-15 {
    font-size: 15px;
  }
  .pt-xxl-5 {
    padding-top: 0;
    /* padding-bottom: 1rem; */
  }
  .pt-llg-1 {
    padding-top: 1rem;
  }
  .carousel-inner {
    /* height: 44.2vh; */
  }
  .inner-h {
    /* height: 28.2vh !important; */
  }
  .px-xxl-5 {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .py-xxl-5 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .py-llg-4 {
    padding-bottom: 3.5rem !important;
  }
  .mb-llg-9 {
    margin-bottom: 10rem !important;
  }
  .mt-lg--16 {
    margin-top: -16%;
  }
  .pb-llg-3 {
    padding-bottom: 4rem;
  }
  .card-d {
    margin: auto;
    margin-top: 3% !important;
  }
  .mb-llg-3 {
    margin-bottom: 3%;
  }
  .pt-llg--1 {
    padding-top: 1rem;
  }
  .mtt-xl-3 {
    margin-top: 0rem !important;
  }
  .pt--79 {
    padding-top: 79px;
  }
  .pt--75 {
    padding-top: 75px;
  }
  .bg-light-v > div > div > div > div > div > .card-d {
    margin-left: 2%;
    margin-top: 5% !important;
  }
  .mll-md-20 {
    margin-left: 17%;
  }
  .p4 {
    font-size: 22px;
  }
  .mll-mdd-5 {
    margin-left: 22%;
  }
  .py--80 {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .py--60 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py--40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .p3-14 {
    font-size: 14px;
  }
  .p25 {
    font-size: 14px;
  }
  .p47 {
    font-size: 34px;
  }
  .large-img-value {
    height: auto;
    width: 378%;
  }
  .small-img-value {
    height: auto;
    width: 607%;
  }
  .mt--10 {
    margin-top: 10%;
  }
  .pxx-10 {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
}

@media (max-width: 770px) {
  .content__container {
    height: 75px !important;
    font-size: 51px !important;
  }
  .p1-23 {
    font-size: 14px;
  }
  .p2-25 {
    font-size: 19px;
  }
  .p3--16 {
    font-size: 16px;
  }
  .pt5 {
    padding-top: 2.5rem !important;
  }
  .p7 {
    font-size: 16px;
  }
  .p6 {
    font-size: 14px;
  }
  .mb--5 {
    margin-bottom: -19%;
  }
  .mt--5 {
    margin-top: -4%;
  }
  .inner-h {
    /* height: 31.2vh !important; */
  }
  .p14 {
    font-size: 18px;
  }
  .mt-lg--16 {
    margin-top: -21%;
  }
  .carousel-inner {
    /* height: 43.5vh; */
  }
  .r-13 {
    right: -3%;
  }
  .p9 {
    font-size: 28px;
  }
  .mll-md-5 {
    margin-left: 22%;
  }
  .mll-md-20 {
    margin-left: 18%;
  }
  .mt-xxl-5 {
    margin-top: 0rem;
  }
  .p12 {
    font-size: 15px;
  }
  .sticky-top {
    padding: 15px 6px !important;
  }
  .main-navbar {
    top: -1px !important;
  }
  .py---80 {
    padding-top: 0px;
    padding-bottom: 2px;
  }
  .f-21 {
    font-size: 23px;
  }
  .p-9 {
    font-size: 21px;
  }
  .p15 {
    font-size: 14px;
  }
  .f-25 {
    font-size: 26px;
  }
  .f-24 {
    font-size: 27px;
  }
  .mll-mdd-5 {
    margin-left: 0%;
  }
  .large-img-value {
    height: auto;
    width: 382%;
  }
  .mt--10 {
    margin-top: 19%;
  }
  .py--60 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

@media (max-width: 545px) {
  .mll-md-20 {
    margin-left: 0%;
  }
  .mll-md-5 {
    margin-left: 0%;
  }
  .p3-15 {
    font-size: 17px;
  }
  .p3-14 {
    font-size: 19px;
  }
  .p8 {
    font-size: 14px;
  }
  .font-16 {
    font-size: 11px;
  }
  .p25 {
    font-size: 17px;
  }
  .inner-h {
    /* height: 38vh !important; */
  }
  .content__container {
    height: 9.4vh;
    font-size: 51px;
  }
}

@media (max-width: 500px) {
  .pt--75 {
    padding-top: 0;
  }
  .mll-md-20 {
    margin-left: 0%;
  }
  .mll-md-5 {
    margin-left: 0%;
  }
  .p3-14 {
    font-size: 14px;
  }
  .p3-15 {
    font-size: 15px;
  }
  .p9 {
    font-size: 25px;
  }
  .p9-22 {
    font-size: 22px;
  }
}

@media (max-width: 500px) {
  .p25 {
    font-size: 15px;
  }
  .p8 {
    font-size: 13px;
  }
  .content__container {
    height: 75px !important;
    font-size: 51px !important;
  }
  .inner-h {
    /* height: 39vh !important; */
  }
  .p9 {
    font-size: 22px;
  }
}

/* mobile max */

@media (max-width: 380px) {
  .pt--75 {
    padding-top: 100px;
  }
  .content__container {
    height: 59px !important;
    font-size: 3rem !important;
  }
  .p-xl-resp {
    padding: 1.8rem !important;
  }
  .carousel-inner {
    /* height: 42vh; */
  }
  .r-13 {
    right: 0%;
  }
  .p2-25 {
    font-size: 17px;
  }
  .p4 {
    font-size: 24px;
  }
  .inner-h {
    /* height: 44.2vh !important; */
  }
  .mt--xxl-5 {
    margin-top: 5rem;
  }
  .pr-pl-5 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }
  .col-desktop-w {
    flex: 0 0 90% !important;
    max-width: 86% !important;
  }
  .f-24 {
    font-size: 22px;
  }
  .py-42 {
    padding-top: 42px !important;
    padding-bottom: 42px !important;
  }
  .sticky-top {
    padding: 10px 8px !important;
  }
  .p-9 {
    font-size: 26px;
  }
  .f-21 {
    font-size: 21px !important;
  }
  .f-25 {
    font-size: 23px;
  }
  .pb--80 {
    padding-bottom: 40px !important;
  }
  .pt--80 {
    padding-top: 40px;
  }
  .mt-lg--16 {
    margin-top: -45%;
  }
  .pb-llg-3 {
    padding-bottom: 0.5rem;
  }
  .py--80 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .py--60 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .py--40 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .p8 {
    font-size: 15px;
  }
  .p25 {
    font-size: 12px;
  }
  .p-25 {
    font-size: 25px;
  }
  .p47 {
    font-size: 30px;
  }
  .p15 {
    font-size: 12px;
    line-height: 1.4;
  }
  .p-22 {
    font-size: 22px;
  }
  .p11 {
    font-size: 14px;
  }
  .p12 {
    font-size: 12px;
  }
  .mt-xxl-5 {
    margin-top: 0rem;
  }
  .p19 {
    font-size: 23px;
  }
  .p--15 {
    font-size: 16px;
  }
  .mll-md-20 {
    margin-left: 0%;
  }
  .p3 {
    font-size: 16px;
  }
  .svg-resp {
    height: 38% !important;
  }
  .p--19 {
    font-size: 19px;
  }
  .rc-anchor-normal {
    height: 74px;
    width: 199px !important;
  }
  .p-26 {
    font-size: 26px;
  }
  .p-14 {
    font-size: 14px;
  }
  .mll-md-5 {
    margin-left: 0%;
  }
  .p3-12 {
    font-size: 12px;
  }
  .p3-13 {
    font-size: 13px;
  }
  .h-100 {
    height: 71% !important;
  }
}

@media (max-width: 365px) {
  .p-25 {
    font-size: 23px;
  }
  .p-14 {
    font-size: 13px;
  }
  .p-22 {
    font-size: 21px;
  }
  .p--19 {
    font-size: 18px;
  }
  .p-26 {
    font-size: 25px;
  }
  .p8 {
    font-size: 14px;
  }
}

@media (max-width: 320px) {
  .p11 {
    font-size: 13px;
  }
  .p12 {
    font-size: 10px;
  }
  .py---80 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .p1-23 {
    font-size: 12px;
  }
  .content__container {
    height: 59px !important;
    font-size: 39px !important;
  }
  .p2-25 {
    font-size: 15px;
  }
  .p4 {
    font-size: 20px;
  }
  .p-9 {
    font-size: 21px;
  }
  .inner-h {
    /* height: 53.2vh !important; */
  }
  .inner-h > .carousel-inner {
    /* height: 55vh !important; */
  }
  .p8 {
    font-size: 12px;
  }
  .f-21 {
    font-size: 17px !important;
  }
  .p19 {
    font-size: 19px;
  }
  .p15 {
    font-size: 10px;
  }
  .f-25 {
    font-size: 19px;
  }
  .f-24 {
    font-size: 20px;
  }
  .p20 {
    font-size: 13px;
  }
  .exp-card-height {
    height: 235px;
  }
}

/* small desktop min*/

@media (min-width: 1360px) {
  .m-contact {
    margin-top: -17%;
  }
  .col-resp {
    flex: 0 0 96.666667% !important;
    max-width: 96.666667% !important;
  }
}
@media (max-width: 375px) {
  .carousel-indicators {
    bottom: -14% !important;
  }
}
/* small desktop min*/

@media (min-width: 1700px) {
  .my-xxxl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .pxx-3 {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .getin-btn,
  .profile-btn {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .floating-label > label {
    /* font: normal normal bold 16px/25px Helvetica !important; */
  }
  #react-select-3-placeholder {
    font: normal normal bold 16px/25px Helvetica !important;
  }
  .p-xl-resp {
    padding: 3.5rem !important;
  }
  .line-h-2 {
    line-height: 2 !important;
  }
  .my-xxl-4 {
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
  }
  .mx-xxl-3 {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }
  .mb-xxl-5 {
    margin-bottom: 3rem;
  }
  .py-xxl-5 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .py-desktop-3 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .pt-xxl-1 {
    padding-top: 0.25rem;
  }
  .px-xxl-5 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .pt-xxl-5 {
    padding-top: 3rem;
  }
  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .py-xxl-3 {
    padding-bottom: 1rem !important;
    padding-top: 1rem !important;
  }
  .mt-xxl-5 {
    margin-top: 3rem;
  }
  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xxl-5 {
    padding-left: 3rem !important;
  }
  .ml-xxl-5 {
    margin-left: 3rem !important;
  }
  .pr-xxl-3 {
    padding-right: 1rem !important;
  }
  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  .p20 {
    line-height: 2;
  }
  .pl-xxl-3 {
    padding-left: 1rem !important;
  }
  .pl-xxl-5 {
    padding-left: 2.5rem !important;
  }
  .col-xxl-4 {
    max-width: 31.6% !important;
  }
  .case-btn-h {
    height: 60px;
  }
  .serv-dim {
    width: 224px;
    height: 60px;
  }
  .col-xxl-9 {
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }
  .mx-xxl-1 {
    margin-left: 0.6rem;
    margin-right: 0.6rem;
  }
  .car1-content {
    right: 1%;
    margin-top: 8%;
  }
  .w-resp-h {
    width: 290px;
  }
  .mx-xxl-4 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .col-resp {
    flex: 0 0 90.666667% !important;
    max-width: 90.666667% !important;
  }

  .carousel-inner {
    /* height: 100%; */
  }
  .card-d-para {
    height: auto !important;
  }
  .mb--5 {
    margin-bottom: -13%;
  }

  .mt--5 {
    margin-top: -5%;
  }
  .linee-h-2 {
    line-height: 1.8 !important;
  }
  .py--80 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .py--60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .py--40 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .pt--80 {
    padding-top: 80px;
  }
  .pb--80 {
    padding-bottom: 80px;
  }
  .large-img-value {
    height: 240px;
    object-fit: cover;
  }
  .small-img-value {
    height: 140px;
    object-fit: cover;
  }
  .inner-h {
    /* height: 55vh !important; */
  }
}

.carousel-indicators li {
  width: 65px !important;
  height: 7px !important;
  border-radius: 14px;
  background-color: #50c878 !important;
  border: 6px solid transparent !important;
  transition: opacity 0.6s ease;
}
.carousel-inner {
  overflow: hidden !important;
}